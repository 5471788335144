import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';
import JustValidatePluginDate from 'just-validate-plugin-date';

export default class extends Controller {
	static targets = ['email', 'isForeignerCbx'];
	validation = {};

	static values = {
	  survey: { type: Boolean, default: false }
	}

	connect(){
		this.validation = new JustValidate(this.element);

		document.addEventListener('foreingerCbxChanged', this.foreignerHandler.bind(this));

		if(this.surveyValue){
			this.prepareSurveyValidation()
		}

		this.validation
		  .addField("#patient_email_id", [
		    {
		      rule: 'required',
		      errorMessage: 'Proszę wpisać adres e-mail',
		    },
		    {
		      rule: 'email',
		      errorMessage: 'Proszę wpisać poprawny adres e-mail',
		    },
		  ]).addField('#patient_email_confirmation_id', [
			  {			
			  	errorMessage: 'Obydwa pola z adresem e-mail powinny zawierać ten sam adres',  	
			    validator: (value) => value === this.emailTarget.value,
			  },
			  {
		      rule: 'required',
		      errorMessage: 'Proszę ponownie wpisać adres e-mail',
		    },
		    {
		      rule: 'email',
		      errorMessage: 'Proszę wpisać poprawny adres e-mail',
		    }
			]).addField("#sample_collection_date_id", [
		    {
		      rule: 'required',
		      errorMessage: 'Proszę wpisać datę pobrania materiału',
		    },
		    {
			    plugin: JustValidatePluginDate(() => ({
			      format: 'dd.MM.yyyy',
			    })),
			    errorMessage: 'Data pobrania musi być wpisana w formacie DD.MM.RRRR (np. 02.11.1992)',
			  },
		  ]).addField("#patient_first_name_id", [
		    {
		      rule: 'required',
		      errorMessage: 'Proszę wpisać imię pacjenta',
		    },
		    {
		      rule: 'minLength',
		      value: 2,
		      errorMessage: 'Proszę podać pełne imię'
		    },
		  ]).addField("#patient_last_name_id", [
		    {
		      rule: 'required',
		      errorMessage: 'Proszę wpisać nazwisko pacjenta',
		    },
		    {
		      rule: 'minLength',
		      value: 2,
		      errorMessage: 'Proszę podać pełne nezwisko'
		    },
		  ]).addField("#patient_pesel_id", [
		    {
		      rule: 'required',
		      errorMessage: 'Proszę wpisać PESEL pacjenta',
		    },
		    {			
			  	errorMessage: 'Nieprawidłowy numer PESEL',  	
			    validator: (value) => this.validatepesel(value),
			  }
		  ]).addField('#sample_approve_id', [
			  {
		      rule: 'required',
		      errorMessage: 'Zaznaczenie pola z oświadczeniem jest wymagane',
		    }
			]);
	}

	foreignerHandler(e){
		const form = this

		if (e.detail == false) {
			form.validation
			  .addField("#patient_pesel_id", [
		    {
		      rule: 'required',
		      errorMessage: 'Proszę wpisać PESEL pacjenta',
		    },
		    {			
			  	errorMessage: 'Nieprawidłowy numer PESEL',  	
			    validator: (value) => form.validatepesel(value),
			  }
		  ]).removeField("#patient_id_document_id")
			  .removeField('#patient_birthdate_id');
		} else {
			form.validation
			  .removeField("#patient_pesel_id")
			  .addField("#patient_id_document_id", [
			    {
			      rule: 'required',
			      errorMessage: 'Proszę wpisać numer dokumentu tożsamości pacjenta',
			    },
			    {
			      rule: 'minLength',
			      value: 2,
			      errorMessage: 'Proszę podać pełen numer dokumentu tożsamości pacjenta'
			    },
			]).addField('#patient_birthdate_id', [
			  {
			    plugin: JustValidatePluginDate(() => ({
			      format: 'dd.MM.yyyy',
			    })),
			    errorMessage: 'Data urodzenia musi być wpisana w formacie DD.MM.RRRR (np. 02.11.1992)',
			  },
			  {
		      rule: 'required',
		      errorMessage: 'Proszę wprowadzić datę urodzenia pacjenta',
		    }
		  ]);
		}
	}

	prepareSurveyValidation() {
		const collection = document.getElementsByClassName("survey_required_option");

		Array.from(collection).forEach((el) => {
		 this.validation
		  .addField(`#${el.id}`, [
		    {
		      rule: 'required',
		      errorMessage: 'Proszę wypełnić to pole',
		    }
		  ])
		});

		var cbxs = []
		const multi_choice_cbxs = document.getElementsByClassName("survey_multichoice_required_option");
		

		Array.from(multi_choice_cbxs).forEach((el) => {
			cbxs.push(el.id)
		});

		// console.log(new Set(cbxs))

		Array.from(new Set(cbxs)).forEach((el) => {
			this.validation.addRequiredGroup(`#${el}`, 'Proszę wybrać przynajmniej jedną opcję z dostępnych powyżej')
		});

		const weightField = document.getElementById('patient_body_weight');
		if (weightField) {
		    this.validation
				  .addField("#patient_body_weight", [
				    {
				      rule: 'required',
				      errorMessage: 'Proszę wpisać wagę w kg',
				    }
				  ]);
		} else {
		    console.log('patient_body_weight field not found');
		}

		const heightField = document.getElementById('patient_body_height');
		if (heightField) {
		    this.validation
				  .addField("#patient_body_height", [
				    {
				      rule: 'required',
				      errorMessage: 'Proszę wpisać wzrost w cm',
				    }
				  ]);
		} else {
		    console.log('patient_body_height field not found');
		}

		const sample_nordic_walking_rodo_cbxField = document.getElementById('sample_nordic_walking_rodo_cbx_id');
		if (sample_nordic_walking_rodo_cbxField) {
		    this.validation
				  .addField('#sample_nordic_walking_rodo_cbx_id', [
					  {
				      rule: 'required',
				      errorMessage: 'Zaznaczenie pola zgody jest wymagane',
				    }
					]);
		} else {
		    console.log('sample_nordic_walking_rodo_cbx_id field not found');
		}

		const sample_nordic_walking_gum_cbxField = document.getElementById('sample_nordic_walking_gum_cbx_id');
		if (sample_nordic_walking_gum_cbxField) {
		    this.validation
				  .addField('#sample_nordic_walking_gum_cbx_id', [
						  {
					      rule: 'required',
					      errorMessage: 'Zaznaczenie pola zgody jest wymagane',
					    }
						]);
		} else {
		    console.log('sample_nordic_walking_gum_cbx_id field not found');
		}



		// this.validation
		// 		.addField('#sample_approve_id', [
		// 	  {
		//       rule: 'required',
		//       errorMessage: 'Zaznaczenie pola z oświadczeniem jest wymagane',
		//     }
		// 	]);

		// const result = Object.groupBy(cbxs, ({ name }) => name);

		// console.log(result)

		

		


	}

	async submitForm(e){
		e.preventDefault()
		const isValid = await this.validation.revalidate()
		if (isValid) { this.element.submit() }
	}

	validatepesel(pesel) {
	    var reg = /^[0-9]{11}$/;
	    if(reg.test(pesel) == false) 
	    {
	    	return false;
	    }
	    else
	    {
        var dig = (""+pesel).split("");
        var kontrola = (1*parseInt(dig[0]) + 3*parseInt(dig[1]) + 7*parseInt(dig[2]) + 9*parseInt(dig[3]) + 1*parseInt(dig[4]) + 3*parseInt(dig[5]) + 7*parseInt(dig[6]) + 9*parseInt(dig[7]) + 1*parseInt(dig[8]) + 3*parseInt(dig[9]))%10;
        if(kontrola==0) kontrola = 10;
        kontrola = 10 - kontrola;
        if(parseInt(dig[10])==kontrola)
        return true;
        else
        return false;
	    }
	}



}
